<template>
  <div class="newsDetails-wrapper">
    <div class="inputs mb-5" @click="goBack" style="cursor: pointer">
      <i class="fa fa-arrow-left ml-2" style="color: #434343"></i>
      <span class="ml-1">Back</span>
    </div>
    <div
      class="content-wrapper"
      style="overflow: auto; max-height: calc(100vh - 210px)"
    >
      <div v-if="loading" class="content-loader">
        <ContentLoader
          :speed="2"
          primaryColor="rgb(230, 230, 230)"
          secondaryColor="rgb(240, 240, 240)"
          class="mt-5 p-2"
        ></ContentLoader>
      </div>
      <div v-else class="content-container">
        <div style="width: 700px; height: 300px; float: right">
          <img
            :src="getImageSrc(communication.image_id)"
            alt="News Image"
            class="floated-image"
          />
        </div>
        <div>
          <h3>{{ communication.name }}</h3>
          <div v-html="communication.description" class="text-content"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { NewsService } from "@/services/news";
import { ContentLoader } from "vue-content-loader";
import { useUserStore } from "@/stores/UserStore";
export default {
  name: "Communication",
  components: {
    ContentLoader,
  },
  data() {
    return {
      userStore: useUserStore(),
      content: "",
      showOptions: false,
      loading: false,
      communicationName: "",
      communicationDescription: "",
      dueDate: "",
      communication: [],
      displayedItems: 0,
      quill: null,
    };
  },
  created() {
    this.getCommunicationList();
  },
  computed: {
    ...mapState({
      selectedEvent: (state) => state.selectedEvent,
      selectedTraining: (state) => state.selectedTraining,
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
    }),
  },
  watch: {},

  methods: {
    onEditorBlur(editor) {
      console.log("quill", this.quill.editor);
      this.content = this.quill.editor.scroll.domNode.innerHTML;
    },
    testready(quill) {
      this.quill = quill;
    },
    goBack() {
      this.$router.go(-1);
    },
    async refreshEverything() {
      this.getCommunicationList();
    },
    truncateString(str) {
      if (str.length > 50) {
        return str.substring(0, 50) + "...";
      } else {
        return str;
      }
    },
    getImageSrc(image_id) {
      const myBlobToken = this.userStore.blobToken;
      return `https://stmzgdsengage.blob.core.windows.net/news/${image_id}?${myBlobToken}`;
    },
    async getCommunicationList() {
      try {
        this.loading = true;
        const id = this.$route.params.id;
        const { data } = await NewsService.get_specific_news(id);
        this.communication = data.data;
      } catch (error) {
        console.error("Error fetching News data:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.newsDetails-wrapper {
  margin-top: 55px;
}
.image-container {
  height: calc(100vh - 450px);
  width: 100%;
  object-fit: contain;
  max-width: 100%;
}
.image-container img {
  width: 100%;
  object-fit: cover;
  height: calc(100vh - 450px);
}
.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
.floated-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
::v-deep .text-content ul {
  list-style-type: disc;
  padding-left: 20px;
}

::v-deep .text-content ol {
  list-style-type: decimal;
  padding-left: 20px;
}

::v-deep .text-content li {
  margin-bottom: 5px;
}
</style>
